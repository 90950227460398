@mixin DswIconButtonSize($name, $buttonSize, $iconSize) {
  .dsw-#{$name}-icon-button {
    width: $buttonSize !important;
    height: $buttonSize !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    & > *[role='img'] {
      width: $iconSize;
      height: $iconSize;
      font-size: $iconSize;

      svg {
        width: $iconSize;
        height: $iconSize;
      }
    }

    .dsw-mdc-button-touch-target {
      width: $buttonSize !important;
      height: $buttonSize !important;
    }
  }
}

@mixin DswIconButtonRoundedBorder() {
  .dsw-rounded-icon-button {
    border-radius: 50% !important;
    border: solid #d0d0d0 1px !important;
  }
}
