@mixin DswButtonSize($name, $buttonSize, $lineHeight, $fontSize) {
  .dsw-#{$name}-button {
    letter-spacing: 0;
    height: $buttonSize;

    .content-wrapper {
      height: calc($buttonSize - 2px);
      line-height: $lineHeight;
      font-size: $fontSize;
    }
  }
}
