@mixin dsw-dark-theme {
  --dsw-background-color: #1f1f1f;
  --dsw-background-color-5: #242424;
  --dsw-background-color-10: #3f3f3f;
  --dsw-background-color-20: #545454;
  --dsw-background-color-30: #858585;
  --dsw-background-color-40: #d0d0d0;
  --dsw-background-color-50: #dedede;
  --dsw-background-color-60: #f0f0f0;
  --dsw-background-color-70: #f6f6f6;
  --dsw-background-color-80: #fafafa;
  --dsw-background-color-90: #fcfcfc;
  --dsw-background-color-95: #ffffff;
  --dsw-text-color: #ffffff;
  --dsw-text-secondary-color: #858585;
  --dsw-text-teriary-color: rgba(255, 255, 255, 0.7);
  --dsw-border-color: #858585;
}

@mixin dsw-light-theme {
  --dsw-background-color: #ffffff;
  --dsw-background-color-5: #fcfcfc;
  --dsw-background-color-10: #fafafa;
  --dsw-background-color-20: #f6f6f6;
  --dsw-background-color-30: #f0f0f0;
  --dsw-background-color-40: #dedede;
  --dsw-background-color-50: #d0d0d0;
  --dsw-background-color-60: #858585;
  --dsw-background-color-70: #545454;
  --dsw-background-color-80: #3f3f3f;
  --dsw-background-color-90: #242424;
  --dsw-background-color-95: #1f1f1f;
  --dsw-text-color: #111111;
  --dsw-text-secondary-color: #858585;
  --dsw-text-teriary-color: #545454;
  --dsw-border-color: #d0d0d0;
}

body {
  --dsw-font-family: 'Inter', 'Inter UI', sans-serif;
  --dsw-vitoragne-color: #ff3e17;
  --dsw-blue-color: #009ade;
  --dsw-white-color: #ffffff;

  @media (prefers-color-scheme: dark) {
    @include dsw-dark-theme;

    &.dsw-light-theme {
      @include dsw-light-theme;
    }
  }

  @media (prefers-color-scheme: light) {
    @include dsw-light-theme;

    &.dsw-dark-theme {
      @include dsw-dark-theme;
    }
  }
}
