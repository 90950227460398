$spacing: 12px;
$bottom-spacing: 12px;
.mobile-toast {
  left: $spacing;
  bottom: $bottom-spacing;
  width: calc(100% - 2 * $spacing - 8px);
}

.default-toast {
  padding: 0;
  background: white;
}

.toast-container {
  pointer-events: unset !important;
  border-radius: 8px !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: 16px;
}
