html, body { 
    margin: 0; 
    min-height: 100vh;
    min-width: 350px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background-color: #F6F6F6; 
    width: 100%;
}

@font-face {
    font-family:"V Regular";
    src:  url("./assets/font/viProductLabel/ViessmannProductLabel-webfont.eot?#iefix");
    src:  url("./assets/font/viProductLabel/ViessmannProductLabel-webfont.eot?#iefix") format("eot"),
    url("./assets/font/viProductLabel/ViessmannProductLabel-webfont.woff") format("woff"),
    url("./assets/font/viProductLabel/ViessmannProductLabel-webfont.ttf") format("truetype"),
    url("./assets/font/viProductLabel/ViessmannProductLabel-webfont.svg#font") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=45c72380-c8a6-43ac-b8c5-2c4809e5a450);

/* 45 Light */
@font-face {
    font-family: "Univers45";
    src: url("./assets/font/cyrillic/UniversLTW02-45.eot?#iefix");
    src: url("./assets/font/cyrillic/UniversLTW02-45.eot?#iefix") format("eot"), url("./assets/font/cyrillic/UniversLTW02-45.woff") format("woff"), url("./assets/font/cyrillic/UniversLTW02-45.ttf") format("truetype"), url("./assets/font/cyrillic/UniversLTW02-45.svg#af931ed4-a6e9-46cf-8f5e-e3ee804ae773") format("svg");
    font-weight: normal; font-style: normal;
}
/* 45 Light Oblique */
@font-face {
    font-family: "Univers45Obl";
    src: url("./assets/font/cyrillic/UniversLTW02-45Obl.eot?#iefix");
    src: url("./assets/font/cyrillic/UniversLTW02-45Obl.eot?#iefix") format("eot"), url("./assets/font/cyrillic/UniversLTW02-45Obl.woff") format("woff"), url("./assets/font/cyrillic/UniversLTW02-45Obl.ttf") format("truetype"), url("./assets/font/cyrillic/UniversLTW02-45Obl.svg#9e59d0aa-3be1-4a8f-b1bf-a041b40fcb90") format("svg");
    font-weight: normal; font-style: normal;
}
/* 55 Roman */
@font-face {
    font-family: "Univers55";
    src: url("./assets/font/cyrillic/UniversLTW02-55.eot?#iefix");
    src: url("./assets/font/cyrillic/UniversLTW02-55.eot?#iefix") format("eot"), url("./assets/font/cyrillic/UniversLTW02-55.woff") format("woff"), url("./assets/font/cyrillic/UniversLTW02-55.ttf") format("truetype"), url("./assets/font/cyrillic/UniversLTW02-55.svg#3f5c7518-9c93-4204-b15b-ce95732d6402") format("svg");
    font-weight: normal; font-style: normal;
}
/* 57 Condensed */
@font-face {
    font-family: "Univers57";
    src: url("./assets/font/cyrillic/UniversLTW02-57.eot?#iefix");
    src: url("./assets/font/cyrillic/UniversLTW02-57.eot?#iefix") format("eot"), url("./assets/font/cyrillic/UniversLTW02-57.woff") format("woff"), url("./assets/font/cyrillic/UniversLTW02-57.ttf") format("truetype"), url("./assets/font/cyrillic/UniversLTW02-57.svg#908a34b6-a4c0-4360-86ab-dc18b7a1f923") format("svg");
    font-weight: normal; font-style: normal;
}

@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=45c72380-c8a6-43ac-b8c5-2c4809e5a450);

/* 45 Light */
@font-face {
  font-family: "Univers45";
  src: url("./assets/font/latin/UniversLTW01-45.eot?#iefix");
  src: url("./assets/font/latin/UniversLTW01-45.eot?#iefix") format("embedded-opentype"), url("./assets/font/latin/UniversLTW01-45.woff") format("woff"), url("./assets/font/latin/UniversLTW01-45.ttf") format("truetype"), url("./assets/font/latin/UniversLTW01-45.svg#af931ed4-a6e9-46cf-8f5e-e3ee804ae773") format("svg");
  font-weight: normal; font-style: normal;
}
/* 45 Light Oblique */
@font-face {
  font-family: "Univers45Obl";
  src: url("./assets/font/latin/UniversLTW01-45Obl.eot?#iefix");
  src: url("./assets/font/latin/UniversLTW01-45Obl.eot?#iefix") format("embedded-opentype"), url("./assets/font/latin/UniversLTW01-45Obl.woff") format("woff"), url("./assets/font/latin/UniversLTW01-45Obl.ttf") format("truetype"), url("./assets/font/latin/UniversLTW01-45Obl.svg#9e59d0aa-3be1-4a8f-b1bf-a041b40fcb90") format("svg");
  font-weight: normal; font-style: normal;
}
/* 55 Roman */
@font-face {
  font-family: "Univers55";
  src: url("./assets/font/latin/UniversLTW01-55.eot?#iefix");
  src: url("./assets/font/latin/UniversLTW01-55.eot?#iefix") format("embedded-opentype"), url("./assets/font/latin/UniversLTW01-55.woff") format("woff"), url("./assets/font/latin/UniversLTW01-55.ttf") format("truetype"), url("./assets/font/latin/UniversLTW01-55.svg#3f5c7518-9c93-4204-b15b-ce95732d6402") format("svg");
  font-weight: normal; font-style: normal;
}
/* 57 Condensed */
@font-face {
  font-family: "Univers57";
  src: url("./assets/font/latin/UniversLTW01-57.eot?#iefix");
  src: url("./assets/font/latin/UniversLTW01-57.eot?#iefix") format("embedded-opentype"), url("./assets/font/latin/UniversLTW01-57.woff") format("woff"), url("./assets/font/latin/UniversLTW01-57.ttf") format("truetype"), url("./assets/font/latin/UniversLTW01-57.svg#908a34b6-a4c0-4360-86ab-dc18b7a1f923") format("svg");
  font-weight: normal; font-style: normal;
}

@import url(https://fast.fonts.net/t/1.css?apiType=css&projectid=45c72380-c8a6-43ac-b8c5-2c4809e5a450);

/* 45 Light */
@font-face {
  font-family: "Univers45";
  src: url("./assets/font/latinExt/UniversLTW10-45.eot?#iefix");
  src: url("./assets/font/latinExt/UniversLTW10-45.eot?#iefix") format("eot"), url("./assets/font/latinExt/UniversLTW10-45.woff") format("woff"), url("./assets/font/latinExt/UniversLTW10-45.ttf") format("truetype"), url("./assets/font/latinExt/UniversLTW10-45.svg#af931ed4-a6e9-46cf-8f5e-e3ee804ae773") format("svg");
  font-weight: normal; font-style: normal;
}
/* 45 Light Oblique */
@font-face {
  font-family: "Univers45Obl";
  src: url("./assets/font/latinExt/UniversLTW10-45Obl.eot?#iefix");
  src: url("./assets/font/latinExt/UniversLTW10-45Obl.eot?#iefix") format("eot"), url("./assets/font/latinExt/UniversLTW10-45Obl.woff") format("woff"), url("./assets/font/latinExt/UniversLTW10-45Obl.ttf") format("truetype"), url("./assets/font/latinExt/UniversLTW10-45Obl.svg#9e59d0aa-3be1-4a8f-b1bf-a041b40fcb90") format("svg");
  font-weight: normal; font-style: normal;
}
/* 55 Roman */
@font-face {
  font-family: "Univers55";
  src: url("./assets/font/latinExt/UniversLTW10-55.eot?#iefix");
  src: url("./assets/font/latinExt/UniversLTW10-55.eot?#iefix") format("eot"), url("./assets/font/latinExt/UniversLTW10-55.woff") format("woff"), url("./assets/font/latinExt/UniversLTW10-55.ttf") format("truetype"), url("./assets/font/latinExt/UniversLTW10-55.svg#3f5c7518-9c93-4204-b15b-ce95732d6402") format("svg");
  font-weight: normal; font-style: normal;
}
/* 57 Condensed */
@font-face {
  font-family: "Univers57";
  src: url("./assets/font/latinExt/UniversLTW10-57.eot?#iefix");
  src: url("./assets/font/latinExt/UniversLTW10-57.eot?#iefix") format("eot"), url("./assets/font/latinExt/UniversLTW10-57.woff") format("woff"), url("./assets/font/latinExt/UniversLTW10-57.ttf") format("truetype"), url("./assets/font/latinExt/UniversLTW10-57.svg#908a34b6-a4c0-4360-86ab-dc18b7a1f923") format("svg");
  font-weight: normal; font-style: normal;
}