*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  
}

.dsw--mb-\[2px\] {
  margin-bottom: -2px
}

.dsw-mb-2 {
  margin-bottom: 0.5rem
}

.dsw-flex {
  display: flex
}

.dsw-inline-flex {
  display: inline-flex
}

.dsw-h-full {
  height: 100%
}

.dsw-grow {
  flex-grow: 1
}

.dsw-flex-row {
  flex-direction: row
}

.dsw-flex-col {
  flex-direction: column
}

.dsw-items-start {
  align-items: flex-start
}

.dsw-items-center {
  align-items: center
}

.dsw-justify-start {
  justify-content: flex-start
}

.dsw-justify-end {
  justify-content: flex-end
}

.dsw-justify-center {
  justify-content: center
}

.dsw-justify-between {
  justify-content: space-between
}

.dsw-justify-items-center {
  justify-items: center
}

.dsw-gap-1 {
  gap: 0.25rem
}

.dsw-gap-14 {
  gap: 3.5rem
}

.dsw-gap-2 {
  gap: 0.5rem
}

.dsw-gap-4 {
  gap: 1rem
}

.dsw-gap-6 {
  gap: 1.5rem
}

.dsw-gap-8 {
  gap: 2rem
}

.dsw-self-start {
  align-self: flex-start
}

.dsw-self-center {
  align-self: center
}

.dsw-text-ellipsis {
  text-overflow: ellipsis
}

.dsw-rounded-sm {
  border-radius: 0.125rem
}

.dsw-border {
  border-width: 1px
}

.dsw-border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity))
}

.dsw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.dsw-p-4 {
  padding: 1rem
}

.dsw-p-6 {
  padding: 1.5rem
}

.dsw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.dsw-py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px
}

.dsw-text-center {
  text-align: center
}

.dsw-text-\[14px\] {
  font-size: 14px
}

.dsw-text-base {
  font-size: 1rem;
  line-height: 1.5rem
}

.dsw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.dsw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.dsw-font-normal {
  font-weight: 400
}

.dsw-font-semibold {
  font-weight: 600
}

.dsw-leading-\[18px\] {
  line-height: 18px
}

.dsw-leading-normal {
  line-height: 1.5
}

.dsw-leading-snug {
  line-height: 1.375
}

.dsw-leading-tight {
  line-height: 1.25
}

.dsw-tracking-normal {
  letter-spacing: 0em
}

.dsw-text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity))
}

.dsw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))
}

@media (min-width: 768px) {
  .md\:dsw-flex-row {
    flex-direction: row
  }

  .md\:dsw-items-center {
    align-items: center
  }

  .md\:dsw-gap-6 {
    gap: 1.5rem
  }
}