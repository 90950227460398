.dsw-link {
  color: var(--dsw-text-color);
  text-decoration: none;
  font-family: var(--dsw-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
